<template>
	
	<div id="presentation" class="container">
		<h2>Experiences Professionnelles</h2>

		<!--
		<div class="row" id="experience">
			<div id="informations" class="col-md-4 col-sm-2" v-for="metiers in metier" :key="metiers.job">
				<div class="card">

					<div class="card-body">
						<div class="card-title" id="titre" >
							{{metiers.job}}
						</div>
						<div class="card-text" id="info">
							{{metiers.date}}
						</div>
						<div class="cart-text" id="info">
							{{metiers.duree}}
						</div>
					</div>
				</div>
			</div>	
		</div>
		-->

		<div class="container" id="informations">
			<div class="row experience-pad">
				<div class="col-md-12 col-sm-12 col-xs-12">
					<section class="main-timeline-section">
						<div class="timeline-start"></div>
						<div class="conference-center-line"></div>
						<div class="conference-timeline-content" v-for="(metiers,index) in metier" :key="metiers.job">
							<div class="timeline-article timeline-article-top" v-if="(index%2==0)">
								<div class="content-date">
									<!-- <span>{{metiers.date}} {{metiers.duree}}</span> -->
								</div>
								
								<div class="meta-date"></div>
								<div class="content-box">
									<span>{{metiers.date}} {{metiers.duree}}</span>
									<p class="timelineTitle">{{metiers.job}}</p>
									
									<b-button v-b-modal="modalId(index)" id="button-exp" ><i class="fa fa-plus" aria-hidden="true"> infos</i>  </b-button>
									<b-modal :id="'modal' + index"  :title="metiers.job"  cancel-title="Fermer"  >
										<div class="modal-body">
											<p>{{metiers.description}}</p>
										</div>
										<p>  </p>
										
									</b-modal>
								</div>
								
							</div>

							<div class="timeline-article timeline-article-bottom" v-if="(index%2!=0)">
								<div class="content-date">
									<!-- <span>{{metiers.date}} {{metiers.duree}}</span> -->

								</div>
								<div class="meta-date"></div>
								<div class="content-box">
									<p class="timelineTitle">{{metiers.job}}</p>

									<b-button v-b-modal="modalId(index)" id="button-exp"><i class="fa fa-plus" aria-hidden="true"> infos</i>  </b-button>
									<b-modal :id="'modal' + index"  :title="metiers.job" cancel-title="Fermer" >
										<div class="modal-body">
											<p>{{metiers.description}}</p>
										</div>
										<p></p>
									
									</b-modal>
									<div class="container">
										
										<span>{{metiers.date}} {{metiers.duree}}</span> 

									</div>
								</div>
							</div>

							
						</div>
						<div class="timeline-end"></div>
					</section>
				</div>
			</div>
		</div>

	<div>
	
</div>
  

	</div>
</template>

<script>
export default {
	name:'Experiences',
	data(){
		return{
			metier:[
				{
					job:'Equipière polyvalente', 
					date:"07/2019 - ",
					duree:'en cours',
					description:"Étant actuellement équipière polyvalente à Lidl, je suis amenée à me charger des caisses "+
					"  ainsi que de la mise en rayon. Je m'occupe également de la récéption du camion, de la boulangerie, et tout ce qui se passe dans le magasins."},
				{
					job:'Rayonniste',
					date:"06/2019 - ",
					duree:'1 semaine',
					description:'Je devais mettre les articles dans les rayons '},
				
				{
					job:'Inventoriste',
					date:"05/2019 - ",
					duree:'1 semaine',
					description:"Je devais compter le nombre d article, et les inscrire dans un MDE "},
				
				
				
			],
		}
	},
	methods:{
		getMetier(){
			return this.metier;
		},
		modalId(i) {
			return 'modal' + i;
		}
	}

}
</script>


<style >
	@import url(../assets/stylesheet_css/timelineH.css);
</style>